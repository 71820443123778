const assetBucketName = `assets.workingconcept.com`
const bucketDistributionBase = `https://d2n8iz40fhlkkc.cloudfront.net`
const imageKitTransformBase = `https://ik.imagekit.io/wrk`

const mimeTypes = {
  jpg: `image/jpeg`,
  png: `image/png`,
  webp: `image/webp`,
  gif: `image/gif`,
}

export function ratioClass() {}

export function generateSrcset({
  url,
  widthRatios = [],
  extension,
  width,
  height,
  radius,
}) {
  let srcset = []

  widthRatios.forEach(ratio => {
    const targetW = Math.round(width * ratio)
    srcset.push(
      `${getTransformUrl({
        url: url,
        type: 'inside',
        width: targetW,
        height: height,
        extension: extension,
        radius: radius,
      })} ${targetW}w`
    )
  })

  return {
    mime: mimeTypes[extension],
    srcset: srcset,
  }
}

/**
 * Generates an image transform URL based on the provided parameters.
 *
 * @param {
 *  url,
 *  width,
 *  height,
 *  type, // `cover`, `contain`, `fill`, `inside`, `outside`
 *  gravity,
 *  extension, // `jpeg`, `png`, `webp`, `tiff`
 *  filters,
 *  quality,
 *  radius
 * } props
 *
 * TODO: support filters
 */
export function getTransformUrl(props) {
  //console.log(`getTransformUrl: `, props)
  const validTypes = ['cover', 'contain', 'fill', 'inside', 'outside']
  const validExtensions = ['jpg', 'jpeg', 'png', 'webp', 'tiff']

  if (props.type && validTypes.indexOf(props.type) === -1) {
    console.error(
      `Invalid transform type "${
        props.type
      }"; must be one of: ${validTypes.join(', ')}`
    )
    return
  }

  if (props.extension && validExtensions.indexOf(props.extension) === -1) {
    console.error(
      `Can't transform "${props.url}"; must be one of: ${validExtensions.join(
        ', '
      )}`
    )
    return
  }

  // default to scaling image within provided dimensions
  props.type = props.type || 'inside'

  // TODO: support gravity
  props.gravity = props.gravity || 'ce'

  props.filters = props.filters || {}
  props.radius = props.radius || 0
  props.quality = props.quality || 90

  return getImageKitTransformUrl(props)
}

function getImageKitTransformUrl(props) {
  if (props.url.indexOf(bucketDistributionBase) !== -1) {
    // convert static CloudFront URL to bucket base
    props.url = props.url.replace(bucketDistributionBase, ``)
  }

  // determine target extension if one wasn't provided
  if (!props.extension) {
    props.extension = getExtension(props.url)
  }

  let transforms = []

  transforms.push('w-' + props.width)
  transforms.push('h-' + props.height)
  transforms.push('q-' + props.quality)
  transforms.push('f-' + props.extension)

  if (props.type) {
    if (props.type === 'cover') {
      transforms.push('c-maintain_ratio')
    } else if (props.type === 'contain') {
      transforms.push('c-at_max')
    } else {
      transforms.push('c-at_max')
    }
  } else {
    // default to something like “contain”
    transforms.push('c-at_max')
  }

  if (props.filters.hasOwnProperty('blur')) {
    transforms.push('bl-' + props.filters.blur)
  }

  if (props.radius === 'max' || props.radius > 0) {
    transforms.push('r-' + props.radius)
  }

  let transformSegment = encodeURI(transforms.join(','))

  const url = `${imageKitTransformBase}/tr:${transformSegment}${props.url}`

  const edits = {
    ...props.filters,
    resize: {
      width: props.width,
      height: props.height,
      fit: props.type,
      quality: props.quality,
    },
  }

  edits[props.extension] = {
    quality: props.quality,
  }

  if (props.extension === 'webp') {
    edits['webp'].lossless = true
  }

  return url
}

// rewrite CloudFront static distribution base URL to bucket base URL
export function rewriteCloudFrontBaseToS3(url) {
  return url.replace(bucketDistributionBase, `https://${assetBucketName}`)
}

export function getExtension(url) {
  const urlParts = url.split(`.`)
  return urlParts.pop()
}

export const getMetaImageUrl = asset => {
  const targetDimensions = {
    width: 1200,
    height: 630,
  }

  let ext = getExtension(asset.url)

  // make sure we provide a png or jpg
  if (['png', 'jpg'].indexOf(ext) === -1) {
    ext = 'png'
  }

  let params = {
    url: asset.url,
    width: targetDimensions.width,
    height: targetDimensions.height,
    extension: ext,
    type: 'cover',
  }

  if (asset.focalPoint) {
    params.gravity = `fp:${asset.focalPoint[0]}:${asset.focalPoint[1]}`
  }

  return getTransformUrl(params)
}
