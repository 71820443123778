import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import TopNav from './TopNav'
import HomeLogo from '../img/logo-white-fill.svg'
import Logo from '../img/logo-white-stroke.svg'
import SVG from 'react-inlinesvg'

function Header({ siteTitle, menuLinks, isHome = false }) {
  const headerClasses = isHome ? 'home flex flex-col h-screen items-center justify-center' : 'mb-36 clearfix pt-16 mx-auto'
  const logoWidth = isHome ? 'w-330' : 'w-180'

  return (
    <header
      className={`tilty-rect max-w-huge text-center ${headerClasses}`}
    >
      <Link
        to="/"
        role="img"
        aria-label="Logo and homepage link"
        className={isHome ? `${logoWidth} block md:ml-16 md:float-left` : `${logoWidth} mx-auto block md:ml-16 md:float-left`}
      >
        {isHome ? <SVG src={HomeLogo} /> : <SVG src={Logo} />}
      </Link>
      <TopNav menuLinks={menuLinks} isHome={isHome} />
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
  menuLinks: PropTypes.array,
}

Header.defaultProps = {
  siteTitle: ``,
  menuLinks: [],
}

export default Header
