import { default as widontMethod } from 'widont'
import moment from 'moment'
import { default as slugifyMethod } from 'slugify'

export function responsiveWidont(string) {
  const glue = `~~`
  return widont(string, { space: glue, hyphen: glue }).replace(
    glue,
    `<span class="widont"> </span>`
  )
}

export function widont(string) {
  return widontMethod(string)
}

export function formatPrice(price) {
  if (price > 0) {
    return (
      `$` +
      price
        .toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        })
        .replace(/\.00$/, '')
    ).replace('$$', '$')
  }
  return 'free'
}

export function formatDate(date, format) {
  return moment(date).format(format)
}

export function slugify(string) {
  return slugifyMethod(string, { lower: true, remove: /[*+~.()'"!:@?]/g })
}
