import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
import { getMetaImageUrl } from '../utils/images'
import logo from '../img/logo/stroke.png'

function SEO({
  description,
  lang,
  additionalMeta = [],
  additionalLink = [],
  keywords,
  title,
  image,
  isHome = false,
  cardSize = `normal`,
  structuredData = [], // schema entities
}) {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            siteUrl
            locale
            foundingDate
          }
        }
        craftGql {
          globalMeta: globalSets(handle: ["defaults"]) {
            ... on CraftGQL_defaults_GlobalSet {
              metaImage {
                url
                focalPoint
              }
              metaDescription
            }
          }
          globalProfile: globalSets(handle: ["profile"]) {
            ... on CraftGQL_profile_GlobalSet {
              companyName
              email
              phoneNumber
              sameAs {
                label
                url
              }
              currenciesAccepted
              priceRange
              region
              placename
              latitude
              longitude
              street
              city
              locality
              postalCode
            }
          }
        }
      }
    `
  )

  const configMeta = data.site.siteMetadata
  const craftMeta = data.craftGql.globalMeta[0]
  const craftProfile = data.craftGql.globalProfile[0]
  const metaDescription = description || configMeta.description

  let defaultImage = false

  if (craftMeta && craftMeta.metaImage) {
    defaultImage = craftMeta.metaImage[0]
  }

  const metaImage = image || defaultImage

  if (cardSize === `normal`) {
    cardSize = `summary`
  } else if (cardSize === `large`) {
    cardSize = `summary_large_image`
  }

  // TODO: automatically get logo dimensions
  //console.log(configMeta)
  let schemaOrgGraph = [
    {
      '@type': 'WebSite',
      name: configMeta.title,
      description: craftMeta.metaDescription,
      publisher: {
        '@id': configMeta.siteUrl + '/#identity',
      },
      url: configMeta.siteUrl,
    },
  ].concat(structuredData)

  let sameAs = []

  craftProfile.sameAs.forEach(row => {
    sameAs.push(row.url)
  })

  // last item
  schemaOrgGraph.push({
    '@id': configMeta.siteUrl + '/#identity',
    '@type': 'Organization',
    address: {
      '@type': 'PostalAddress',
      addressCountry: `US`,
      addressLocality: craftProfile.city,
      addressRegion: craftProfile.locality,
      postalCode: craftProfile.postalCode,
      streetAddress: craftProfile.street,
    },
    name: configMeta.title,
    legalName: craftProfile.companyName,
    url: configMeta.siteUrl,
    description: craftProfile.metaDescription,
    foundingDate: configMeta.foundingDate,
    email: craftProfile.email,
    telephone: craftProfile.phoneNumber,
    image: defaultImage.url,
    sameAs: sameAs,
    logo: {
      '@type': 'ImageObject',
      url: configMeta.siteUrl + logo,
      height: 302,
      width: 772,
    },
  })

  let meta = [
    {
      name: `description`,
      content: metaDescription,
    },
    {
      property: `og:title`,
      content: title,
    },
    {
      property: `og:description`,
      content: metaDescription,
    },
    {
      property: `og:type`,
      content: `website`,
    },
    {
      property: `og:locale`,
      content: data.site.siteMetadata.locale,
    },
    {
      property: `og:site_name`,
      content: data.site.siteMetadata.title,
    },
    {
      name: `twitter:card`,
      content: cardSize,
    },
    {
      name: `twitter:title`,
      content: title,
    },
    {
      name: `twitter:description`,
      content: metaDescription,
    },
    {
      name: `geo.region`,
      content: craftProfile.region,
    },
    {
      name: `geo.placename`,
      content: craftProfile.placename,
    },
  ]

  if (keywords.length > 0) {
    meta.push({
      name: `keywords`,
      content: keywords.join(`, `),
    })
  }

  if (metaImage) {
    const metaImageUrl = getMetaImageUrl(metaImage)
    if (metaImageUrl) {
      meta.push({
        name: `og:image`,
        content: metaImageUrl,
      })
    }
  }

  meta = meta.concat(additionalMeta)

  const schema = {
    type: 'application/ld+json',
    innerHTML: JSON.stringify({
      '@context': 'http://schema.org',
      '@graph': schemaOrgGraph,
    }),
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={isHome ? `%s` : `%s | ${data.site.siteMetadata.title}`}
      meta={meta}
      link={additionalLink}
      script={[schema]}
    >
      {/* custom favicon stuff */}
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon/favicon-16x16.png"
      />
      <link
        rel="mask-icon"
        href="/favicon/safari-pinned-tab.svg"
        color="#00a1d5"
      />
      <link rel="shortcut icon" href="/favicon/favicon.ico" />
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  additionalMeta: PropTypes.array,
  additionalLink: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
  image: PropTypes.object,
  cardSize: PropTypes.oneOf(['normal', 'large']),
  structuredData: PropTypes.arrayOf(PropTypes.object),
}

export default SEO
