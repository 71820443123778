import React from 'react'
import SVG from 'react-inlinesvg'
import Logo from '../img/logo-white-fill.svg'
import { Link, graphql, useStaticQuery } from 'gatsby'
import { slugify } from '../utils/text'

const Footer = ({ companyName }) => {
  const data = useStaticQuery(
    graphql`
      query sameAsQuery {
        craftGql {
          globalProfile: globalSets(handle: ["profile"]) {
            ... on CraftGQL_profile_GlobalSet {
              email
              sameAs {
                label
                url
              }
            }
          }
        }
      }
    `
  )

  const craftProfile = data.craftGql.globalProfile[0]

  return (
    <footer
      className={`bg-charcoal relative px-8 py-24 md:py-32 md:px-16 lg:p-32 text-light-slate`}
    >
      <Link to="/" className={`block mx-auto w-180 md:m-0`}>
        <SVG src={Logo} />
      </Link>

      <nav className={`clearfix px-8 md:px-0 mt-4`}>
        <ul
          className={`list-reset text-center italic flex w-full md:text-left items-center content-center justify-center md:items-start md:content-start md:justify-start`}
        >
          <li>
            <Link className={`block py-1 mx-4 md:mr-6 md:ml-0`} to="/plugins">
              plugins
            </Link>
          </li>
          <li>
            <Link className={`block py-1 mx-4 md:mr-6 md:ml-0`} to="/blog">
              blog
            </Link>
          </li>
          <li>
            <Link className={`block py-1 mx-4 md:mr-6 md:ml-0`} to="/about">
              about
            </Link>
          </li>
        </ul>
      </nav>
    </footer>
  )
}

export default Footer
