import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Header from './Header'
import Footer from './Footer'

const Layout = ({ children, isHome = false }) => {
  const prefersDark =
    typeof window !== 'undefined'
      ? window.matchMedia &&
        window.matchMedia('(prefers-color-scheme: dark)').matches
      : false

  const data = useStaticQuery(
    graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
            topNavLinks {
              label
              url
            }
          }
        }
        craftGql {
          globalProfile: globalSets(handle: ["profile"]) {
            ... on CraftGQL_profile_GlobalSet {
              companyName
            }
          }
        }
      }
    `
  )

  return (
    <>
      <Helmet
        bodyAttributes={{
          class: prefersDark ? 'dark' : 'normal',
        }}
      />
      <Header
        isHome={isHome}
        siteTitle={data.site.siteMetadata.title}
        menuLinks={isHome === false && data.site.siteMetadata.topNavLinks}
        data={data}
      />
      {children}
      {isHome === false && <Footer companyName={data.craftGql.globalProfile[0].companyName} />}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
