import { Link } from 'gatsby'
import React from 'react'
import PropTypes from 'prop-types'

function TopNav({ menuLinks, isHome = false, data }) {
  if (!menuLinks || menuLinks.length === 0) {
    return ''
  }

  const numLinks = menuLinks.length
  const links = menuLinks.map((link, i) => (
    <li className={`italic` + (i < numLinks - 1 ? ` mr-6` : ``)} key={link.url}>
      <Link
        to={link.url}
        className={`font-serif text-white text-xl`}
        activeClassName={`nice-underline hover:text-white`}
      >
        {link.label}
      </Link>
    </li>
  ))

  return (
    <nav
      className={isHome ? `inline-block pt-8 md:float-right md:pr-16 md:pt-0` : `mx-auto inline-block pt-8 md:float-right md:pr-16 md:pt-0`}
    >
      <ul className={`list-reset flex`}>{links}</ul>
    </nav>
  )
}

TopNav.propTypes = {
  menuLinks: PropTypes.array,
  isHome: PropTypes.bool,
}

TopNav.defaultProps = {
  siteTitle: ``,
  menuLinks: [],
}

export default TopNav
